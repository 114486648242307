import { h, Fragment } from 'preact';
import style from './style';
import Header from '../header';
import Shortener, { Results } from '../shortener';
import Stats, { TrackResults } from '../stats';
import { useState, useEffect } from 'preact/hooks';
import config from '../../config.json';
import manifest from '../../manifest.json';

const shortnApi = config.api.url;
const apiKey = config.api.key;
const appTitle = manifest.name;

function fetchFromApi(url, method='GET',data) {
	return fetch(
		url, 
		{ 
			method,
			headers: {
				'Content-Type': 'application/json',
				'X-API-Key':apiKey
			},
			body: data && JSON.stringify(data)
		}
	).then(response => response.json())
}


export default function App() {

	const [shortnUrl, setShortnUrl] = useState();
	const [appState, setAppState] = useState('init');

	const requestServiceInfo = () => {
		fetchFromApi(shortnApi).then(response => {
			if(response) {
				if (shortnUrl !== response.origin) {
					setShortnUrl(response.origin);
					if(appState==='init' || appState === 'noserver') {
						setAppState('shorten');
					}
				}
			} else {
				setShortnUrl(null);
				setAppState('noserver');
			}
		}).catch(() => {
			setShortnUrl(null);
			setAppState('noserver');
		});
	}

	useEffect(() => {
		requestServiceInfo();
		const interval = setInterval(requestServiceInfo, config.api.poll);
		return () => clearInterval(interval);
	}, [shortnUrl, appState]);

	let stateData = {};

	if(appState === 'shorten') {
		stateData = {
			title:'Stats',
			view:<ShortenState shortnUrl={shortnUrl} />
		}
	} else if (appState === 'stats') {
		stateData = {
			title:'Shorten',
			view:<StatsState shortnUrl={shortnUrl} />
		}
	} else if (appState === 'noserver') {
		stateData = {
			title:'',
			view:<NoServerState />
		}
	}

	const handleNavClick = () => {
		if (appState === 'shorten') {
			setAppState('stats');
		} else {
			setAppState('shorten');
		}
	}

	return (
		<div class={style.app}>
			<Header title={appTitle} navtitle={stateData.title} onNavClick={handleNavClick}/>
			{stateData.view}
		</div>
	);
}

function ShortenState({shortnUrl}) {

	const [urls, setUrls] = useState();

	const requestShorten = (request) => {
		fetchFromApi(shortnApi, 'POST', request).then(response => {
			setUrls(response);
		});
	}

	const goToShorten = () => {
		setUrls(null);
	};

	return (
		urls && urls.length ? (
			<Results domain={shortnUrl} results={urls} onAbandon={goToShorten} />
		): (
			<Shortener domain={shortnUrl} onRequest={requestShorten} />
		)
	);

}

function StatsState({shortnUrl}) {

	const [names, setNames] = useState([]);
	const [results, setResults] = useState([]);

	const handleNames = (q) => {
		setNames(q);
	}

	const lookupNames = () => {
		Promise.all(names.map(name => 
			fetchFromApi(`${shortnApi}/${name}`, 'GET').then(results => {
				return results && results.length && results[0];
			})
		)).then(results => {
			results = results.filter(result => result);
			setResults(results)
		})
	}

	const loopLookupNames = () => {
		let interval = setInterval(lookupNames, config.api.stats.poll);
		lookupNames();
		return () => clearInterval(interval);
	}

	const handleClick = (name) => {
		window.open(`${shortnUrl}${name}`);
	}

	useEffect(() => loopLookupNames(), [names]);

	return(
		<Fragment>
			<Stats onNames={handleNames}/>
			{results && (
				<TrackResults results={results} onClick={handleClick}/>
			)}
		</Fragment>
		
	);
}

function NoServerState() {
	return <p>{`${shortnApi} is not available.`}</p>;
}