import { h, Component } from 'preact';
import Input from '../textinput';
import Button from '../button';
import { useState } from 'preact/hooks';
import style from './style';
import copy from 'copy-to-clipboard';
import validUrl from 'valid-url';

export default function Shortener({domain=''}) {

	const [url, setUrl] = useState();
	const [name, setName] = useState();
	
	const isWaitingRoom = url === '!wait';

	let updateUrl = e => {
		setUrl(e && e.target.value || null);
	}

	let updateName = e => {
		setName(e && e.target.value || null);
	}

	let doIt = () => {
		if(!name && !url) {
			alert('You need a name and/or a url.');
			return;
		} else if (isWaitingRoom && !name) {
			alert('Waiting Rooms require a name.');
			return;
		} else if (!isWaitingRoom && url && !validUrl.isUri(url)) {
			alert('You must enter a valid url.');
			return;
		}
		if(this.props.onRequest({name,url})); 
	}

	return (
		<div class={style.shortener}>
			<Input 
				title="Paste A Link" 
				onChange={updateUrl} 
				onEnter={doIt}
				class={style.input}
				tabindex={1}
				placeholder="http://example.com or !wait"
				/>

			{isWaitingRoom && (
				<p class={style.instructions}>You are creating a Waiting Room. 
					Any user that visits the url you create, 
					will see a page asking them to wait until you post 
					the actual url later. Waiting Rooms require a custom name:
				</p>
			)}
			<Input 
				title={isWaitingRoom && "Waiting Room Name" || "Give It A Custom Name?"}
				onChange={updateName} 
				onEnter={doIt}
				class={style.input} 
				hideUntilFocused={!isWaitingRoom}
				caption={`${domain}\${text}`}
				tabindex={3}
				closeTabIndex={2}
				/>

			{isWaitingRoom && (
				<p class={style.instructions}>To update the Waiting Room with an actual 
				url, just return here and shorten the url with the same name as this 
				Waiting Room. Users will see their Waiting Room page auto-magically 
				navigate to that url.
				</p>
			)}
			<Button title={isWaitingRoom ? "Create Waiting Room" : "Shorten"} onClick={doIt} class={style.shortenButton} tabindex={4}/>
		</div>
	);
}

function Result({domain = '', result, onCopy, tabindex}) {

	let short = `${domain}${result.name}`;

	let handleClick = () => {
		copy(short);
		if(onCopy)onCopy(short);
	}

	let handleKeyDown = e => {
		if(event.keyCode === 13) {
			copy(short);
			if(onCopy)onCopy(short);
		}
	}

	return (
		<div class={style.result} onClick={handleClick} onKeyDown={handleKeyDown} tabindex={tabindex}>
			{short}
		</div>
	);
}

function getResultsTitleString(results) {
	if(!results || !results.length) return '';
	let longUrl = results[0].url;
	return results.length > 1 
		? `There are actually multiple shortened urls already. Click any to copy to clipboard:`
		: `Here is your shortened url. Click it to copy to clipboard:` ;
}

export function Results({domain = '',results = [], onAbandon}) {

	let [copyText, setCopyText] = useState();

	let handleAnother = () => {
		onAbandon();
	}

	return (
		<div class={style.results}>
			<p class={style.resultsTitle}>{getResultsTitleString(results)}</p>
			{
				results.map((result, i) => <Result domain={domain} result={result} onCopy={setCopyText} tabindex={i+1} />)
			}
			{copyText && (
				<p class={style.copyText}>{`${copyText} was copied to the clipboard!`}</p>
			)}
			<Button title="Another?" class={style.shortenButton} onClick={handleAnother}/>
		</div>
	);
}