import { h, Component } from 'preact';
import style from './style';

export default function Header({title, navtitle, onNavClick}) {
	return (
		<div class={style.header}>
			<Logo/>
			<span class={style.title}>{title}</span>
			{navtitle && (
				<span class={style.nav} onClick={onNavClick}>{navtitle}</span>
			)}
		</div>
	);
}

function Logo() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
			<g fill="#FFF" fill-rule="evenodd">
				<path d="M6.48 13.83v20.273L24.007 44.24l8.793-5.086v-8.36l-8.793 5.029-10.35-5.92V9.68z"/>
				<path d="M23.986 3.68L15.2 8.76v8.362l8.786-5.024 10.354 5.921v1.31h.011V38.24l7.169-4.145V13.819z"/>
				<path d="M24 33.76l8.64-4.909V19.04L24 23.949z"/>
			</g>
		</svg>
	);
}