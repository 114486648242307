import { h, Component, Fragment } from 'preact';
import style from './style';
import cx from 'classnames';
import { useState } from 'preact/hooks';


function populateCaption(caption, text) {
	return caption.replace('${text}', text && text || '');
}

export default function Input({title, hint, onChange, onEnter, class:cls, hideUntilFocused, caption, tabindex, closeTabIndex, placeholder}) {

	const [opened, setOpened] = useState(!hideUntilFocused);
	const [inputText, setInputText] = useState(!hideUntilFocused);

	const handleKeyDown = e => {
		if(event.keyCode === 13 && onEnter)onEnter();
	}

	const handleKeyDownOpen = e => {
		if(event.keyCode === 13)handleOpen();
	}

	const handleKeyDownClose = e => {
		if(event.keyCode === 13)handleClose();
	}

	const handleOpen = () => setOpened(true);
	const handleClose = () => {
		setOpened(false);
		onChange();
	}
	const handleTextChange = e => {
		setInputText(e.target.value);
		onChange(e);
	}

	return (
		<div class={cls}>
			<div class={style.titleWrapper}>
			{title && (
				<span 
					class={cx(style.title, (hideUntilFocused &&!opened) && style.hidden)} 
					onclick={handleOpen}
					tabindex={hideUntilFocused && !opened && tabindex}
					focusable={hideUntilFocused && !opened}
					onkeydown={handleKeyDownOpen}
					>
					{title}
				</span>
			)}
			{title && hideUntilFocused && opened && (
				<span 
				class={cx(style.titleNope)} 
				onclick={handleClose}
				tabindex={opened && closeTabIndex}
				onkeydown={handleKeyDownClose}
				>
				(Nope)
			</span>
			)}
			</div>
			{(!hideUntilFocused || opened) && (
				<input 
					class={cx(style.input)} 
					type="text" 
					placeholder={hint} 
					onInput={handleTextChange}
					onkeydown={handleKeyDown}
					tabindex={tabindex}
					placeholder={placeholder}>
					</input>
			)}
			{(!hideUntilFocused || opened) && caption && (
				<span 
					class={cx(style.caption)} 
					onclick={handleClose}
					>
					{populateCaption(caption, inputText)}
			</span>
			)}
			
		</div>
		
	);
}