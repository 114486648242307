import { h } from 'preact';
import Input from '../textinput';
import style from './style';
import debounce from 'debounce'

export default function Stats ({onNames}) {
	let updateNames = e => {
		let names = (e && e.target.value || '').split(' ').filter(val => val);
		if(onNames)onNames(names);
	}

	let update = debounce(updateNames, 500);

	return (
		<div class={style.stats}>
			<Input 
				title="Names to watch:" 
				onChange={update} 
				placeholder="Link names (space delimited)"
				/>
		</div>
	);
}


export function TrackResult({name, visits, onClick}) {
	let handleOnClick = () => {
		onClick(name);
	}
	return (
		<div class={style.result} onClick={handleOnClick}>
			<span>{`${visits} visit${visits > 1 ? `s` : ''} to ${name}`}</span>
		</div>
	);
}

export function TrackResults({results, onClick}) {
	return (
		<div class={style.results}>
			{(results && results.length) ? (
				<p class={style.instructions}>The following listings will update periodically. 
				No need to refresh.
				</p>
			):null}
			{results && results.map(result => <TrackResult {...result} onClick={onClick}/>)}
		</div>
	);
}